import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'

export interface TypeInputFieldProps {
    text: string | undefined
    onChange?: (text: string) => void
    onBlur?: (text: string) => void
}

const TypeInputField = ({ text, onBlur }: TypeInputFieldProps) => {
    const [content, setContent] = useState<string>(text ?? '')

    useEffect(() => {
        setContent(text ?? '')
    }, [text])

    const OnBlurEvent = (event: React.FocusEvent<HTMLInputElement>) => {
        setContent(event.target.value)
        if (onBlur === undefined) {
            return
        }
        onBlur(event.target.value)
    }

    const OnChangeEvent = (event: React.FocusEvent<HTMLInputElement>) => {
        setContent(event.target.value)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
                variant='outlined'
                sx={{
                    flexGrow: 1,
                    marginRight: '10px',
                    minWidth: '100px',
                    height: 30,
                    background: '#FBFBFB',
                    '& .MuiInputBase-root': {
                        height: 30,
                    },
                    '& .MuiOutlinedInput-input': {
                        background: {
                            default: '#FFFFFF',
                        },
                    },
                }}
                onChange={OnChangeEvent}
                onBlur={OnBlurEvent}
                value={content}
            />
        </div>
    )

    //<img src={isDynamic?"/dynamic.png":"/fix.png"} style={{width: '20px', height: '20px' }} alt={isDynamic?"dynamic":"fix"} onClick={()=>{setIsDynamic(isDynamic?false:true)}}/>
}

export default TypeInputField
